// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { NODE_ENV } from "./constants";
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://cb0aba01892042b1bd63f61d1ab30d81@o1223400.ingest.sentry.io/6395458",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  // environment: "production",
  release: "web-panel-frontend@1.0.0",
  environment: NODE_ENV,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
