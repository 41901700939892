import { useEffect } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import * as fbq from "../lib/fpixel";
// import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import * as gtag from "../lib/gtag";

// import "../styles/bootstrap.min.css";
// import "../styles/animate.min.css";
// import "../styles/boxicons.min.css";
// import "../styles/meanmenu.min.css";
// import "../styles/flaticon.css";
import "../node_modules/react-modal-video/css/modal-video.min.css";
// import "react-image-lightbox/style.css";
import "../styles/style.scss";
import "../styles/style.css";
import "../styles/responsive.css";
//CKeditor stylesheet
import "../styles/content-styles.css";
import "../styles/pagination.scss";

import Layout from "../components/_App/Layout";
// import TagManager from "react-gtm-module";
import useAxios from "axios-hooks";
import dayjs from "dayjs";

import NextNProgress from "nextjs-progressbar";
import { NODE_ENV, WEB_API } from "../constants";

import randomize from "randomatic";
import ReactGA from "react-ga";
ReactGA.initialize(gtag.GA_TRACKING_ID);

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  const [{ data, loading, error }, refetch] = useAxios(
    WEB_API + "/categories/list"
  );
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();
    // fbq.event("ViewContent");

    const handleRouteChange = (url) => {
      fbq.pageview();
      ReactGA.pageview(window.location.pathname + window.location.search);
      // fbq.event("ViewContent");

      // gtag.pageview(url);
      // gtag.event("new Event", "Test category", "Test label", "Test value");
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    //!commented GTM Module
    if (!router.isReady) return;
    // const tagManagerArgs = {
    //   gtmId: "GTM-KD2WB2C",
    // };

    // TagManager.initialize(tagManagerArgs);

    //Initial FingerPrint Js

    // Initialize an agent at application startup.
    // const fpPromise = FingerprintJS.load({
    //   token: "p79jr6rYr4LMvF4JwgQr",
    //   region: "ap",
    // });
    const fpPromise = FingerprintJS.load();
    let diff = 0;
    const FPJS_UPDATED_ON = dayjs(localStorage.getItem("FPJS_UPDATED_ON"));
    const FPJS = localStorage.getItem("FPJS");
    let has_valid_FPJS_vars =
      localStorage.getItem("FPJS_UPDATED_ON") && FPJS ? true : false;

    const current_date = dayjs(new Date());
    diff = current_date.diff(FPJS_UPDATED_ON, "day", false);

    if (diff > 7 || !has_valid_FPJS_vars) {
      // fpPromise
      //   .then((fp) => fp.get())
      //   .then((result) => {
      //     localStorage.setItem("FPJS", JSON.stringify(result.visitorId));
      //     localStorage.setItem("FPJS_UPDATED_ON", new Date());
      //   });
      const randomID = randomize("a0", 24);
      localStorage.setItem("FPJS", randomID);
      localStorage.setItem("FPJS_UPDATED_ON", new Date());
    }
    // console.log("Browser id", localStorage.getItem("FPJS").replace(/"/g, ""));
  }, [router.isReady]);

  useEffect(() => {
    localStorage.removeItem("unique_order_id");
  }, []);

  return (
    <Layout>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <link rel="stylesheet" type="text/css" href="#" />
      {/* <link rel="stylesheet" type="text/css" href="/static/css/nprogress.css" /> */}
      <link
        href={`https://www.googletagmanager.com/gtag/js?id=${gtag.AW_TRACKING_ID}`}
        rel="preload"
        as="script"
      ></link>
      <link
        href={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        rel="preload"
        as="script"
      ></link>
      <link
        href={`https://www.googletagmanager.com/gtag/js?id=${gtag.GTM_TRACKING_ID}`}
        rel="preload"
        as="script"
      ></link>
      {/*new tags*/}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.AW_TRACKING_ID}`}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GTM_TRACKING_ID}`}
      />
      {/* <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.AW_TRACKING_ID}',{'allow_enhanced_conversions':true});
            gtag('config', '${gtag.GA_TRACKING_ID}');
            gtag('config', '${gtag.GTM_TRACKING_ID}');
          `,
        }}
      /> */}

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      {/* <Script
        strategy="afterInteractive"
        // strategy="lazyOnload"
        id="_webengage_script_tag"
        dangerouslySetInnerHTML={{
          __html: `
          var webengage;!function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),
          arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="feedback survey notification".split(z),c="options render clear abort".split(z),p="Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i < l.length;i++)o(r,[l[i]]);for(i=0;i < a.length;i++){for(r[a[i]]={},s=0;s < c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s < p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i < u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://widgets.in.webengage.com":"http://widgets.in.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");webengage.init("in~311c480d");`,
        }}
      />{" "} */}
      <NextNProgress color="#5172ff" />
      <div id="modal"></div>
      <Component dataNav={data} {...pageProps} />
    </Layout>
  );
};

export default MyApp;
