export const NODE_ENV = "production";

export const WEB_API = "https://web-api.toppersnotes.com";
export const DEV_WEB_API = "https://dev-web-api.toppersnotes.com";
export const ORDER_SERVICE = "https://orders.toppersnotes.com";
export const DEV_ORDER_SERVICE = "https://dev-orders.toppersnotes.com";
export const AUTH_SERVICE = "https://auth.toppersnotes.com";
export const DEV_AUTH_SERVICE = "https://dev-auth.toppersnotes.com";
export const BLOG_SERVICE = "https://blogs-api.dev.toppersnotes.com";
export const DEV_BLOG_SERVICE = "https://blogs-api.dev.toppersnotes.com";

export const BASE_URL = "https://web.toppersnotes.com";
export const FBQ_PIXEL_ID = 846528989241851;

export const EVENT_SERVICE = "https://events.toppersnotes.com"

export const AW_TRACKING_ID_NEXTJS = "AW-967210754";
export const GA_TRACKING_ID_NEXTJS = "UA-232334996-1";
export const GTM_TRACKING_ID_NEXTJS = "G-51RYGW25B1";
